import { CwtValueAccessor } from "@cawita/core-front";
import { findByAddress, ValueAccessor } from "@hints/utils/data";
import { isDate, isNumber, isString } from "@hints/utils/types";
import { compareAsc } from "date-fns";
import { NzTableSortFn } from "ng-zorro-antd/table";

export function createNzTableSortFn<T>(path: ValueAccessor<T, string | number | Date> | CwtValueAccessor<T, string | number | Date>): NzTableSortFn {
    return (a, b) => {
        let vA = findByAddress(a, path);
        let vB = findByAddress(b, path);
        if (isString(vA) && isString(vB)) return vA.localeCompare(String(vB));
        if (isNumber(vA) && isNumber(vB)) return vA - vB;
        if (isDate(vA, true) && isDate(vB, true)) return compareAsc(vA, vB);
        return 0;
    }
}